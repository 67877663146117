export function useUserApi() {
  const API_VER = useRuntimeConfig().API_VER_1
  const API_PROMOTION = useRuntimeConfig().API_PROMOTION
  const createEndpoint = (endpoint: string) => `${API_VER}/${endpoint}`
  const createEndpointPromotion = (endpoint: string) => `${API_PROMOTION}/${endpoint}`

  return {
    REGISTER: createEndpoint('register'),
    LOG_IN: createEndpoint('login'),
    LOG_OUT: createEndpoint('logout'),
    REFRESH: createEndpoint('refresh'),
    VERIFY_USERNAME: createEndpoint('verifyUsername'),
    FORGOT_PASSWORD: createEndpoint('forgotPassword'),
    RESET_PASSWORD: createEndpoint('resetPassword'),
    VERIFY_EMAIL: createEndpoint('verifyEmail'),
    UPDATE_PASSWORD: createEndpoint('updatePassword'),
    UPDATE_INFO: createEndpoint('updateInfo'),
    DEPOSIT_INFO: createEndpoint('payment/indexdeposit'),
    SEND_VERIFY_EMAIL: createEndpoint('user/send-verify-email'),
    VERIFY_EMAIL_TOKEN: createEndpoint('user/verify-email-token'),
    CANCEL_PROMOTION: createEndpoint('payment/cancelpromotion'),
    CURRENT_RANK: createEndpointPromotion('user/rank'),
    RANK_CONFIG: createEndpointPromotion('rank/config')
  }
}
